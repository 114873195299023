import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Layout from '../../components/Layout'
import Seo from '../../components/Seo'
import { seo, intro, main } from '../../content/ochrona-danych'
import { useLangContext } from '../../context/lang.context'
import Intro from '../../components/Intro'
import Main from '../../components/Main'
import Events from '../../components/Events'
import { sHtml } from '../../style'

const files = [
  {
    title: {
      pl: 'Polityka Ochrony Danych Osobowych',
      en: 'Personal Data Protection Policy',
      ua: 'Politika Ochrony Данич Особович',
    },
    desc: {
      pl: 'Wersja polska',
      en: 'Polish version',
      ua: 'польський варіант',
    },
    link: '/pdf/podo_pl.pdf',
    targetBlank: true,
  },
  {
    title: {
      pl: 'Polityka Ochrony Danych Osobowych',
      en: 'Personal Data Protection Policy',
      ua: 'Politika Ochrony Данич Особович',
    },
    desc: {
      pl: 'Wersja angielska',
      en: 'English version',
      ua: 'Англомовна версія',
    },
    link: '/pdf/podo_en.pdf',
    targetBlank: true,
  },
]

const files2 = [
  {
    title: {
      pl: 'Procesu rekrutacji w naszej firmie',
      en: 'Personal Data Protection Policy',
      ua: 'Politika Ochrony Данич Особович',
    },
    desc: {
      pl: '',
      en: 'English version',
      ua: 'Англомовна версія',
    },
    link: '/pdf/kl_inf_rekrutacja.pdf',
    targetBlank: true,
  },
  {
    title: {
      pl: 'Procesu zatrudniania i ochrony danych naszych pracowników',
      en: 'Personal Data Protection Policy',
      ua: 'Politika Ochrony Данич Особович',
    },
    desc: {
      pl: '',
      en: 'English version',
      ua: 'Англомовна версія',
    },
    link: '/pdf/kl_inf_zatrudnienie.pdf',
    targetBlank: true,
  },
  {
    title: {
      pl: 'Zawierania umów',
      en: 'Personal Data Protection Policy',
      ua: 'Politika Ochrony Данич Особович',
    },
    desc: {
      pl: '',
      en: 'English version',
      ua: 'Англомовна версія',
    },
    link: '/pdf/kl_inf_umowy.pdf',
    targetBlank: true,
  },
  {
    title: {
      pl: 'Korzystania z naszych formularzy kontaktowych',
      en: 'Personal Data Protection Policy',
      ua: 'Politika Ochrony Данич Особович',
    },
    desc: {
      pl: '',
      en: 'English version',
      ua: 'Англомовна версія',
    },
    link: '/pdf/kl_inf_form_kontakt.pdf',
    targetBlank: true,
  },
  {
    title: {
      pl: 'Działalności naszej fanpage w serwisie społecznościowym Facebook',
      en: 'Personal Data Protection Policy',
      ua: 'Politika Ochrony Данич Особович',
    },
    desc: {
      pl: '',
      en: 'English version',
      ua: 'Англомовна версія',
    },
    link: '/pdf/kl_inf_facebook.pdf',
    targetBlank: true,
  },
  {
    title: {
      pl: 'Działalności naszej strony w serwisie społecznościowym Linkedin',
      en: 'Personal Data Protection Policy',
      ua: 'Politika Ochrony Данич Особович',
    },
    desc: {
      pl: '',
      en: 'English version',
      ua: 'Англомовна версія',
    },
    link: '/pdf/kl_inf_linkedin.pdf',
    targetBlank: true,
  },
]

const OchronaDanych = () => {
  const { lang } = useLangContext()

  const { image } = useStaticQuery(graphql`
    {
      image: file(
        absolutePath: { regex: "/images/intros/ochrona-danych.jpg/" }
      ) {
        publicURL
        childImageSharp {
          gatsbyImageData(
            layout: FIXED
            quality: 100
            placeholder: BLURRED
            formats: [JPG]
          )
        }
      }
    }
  `)

  const button = {
    text: {
      pl: 'Czytaj',
      en: 'Read',
      ua: 'Прочитайте',
    },
    action: 'SCROLL',
  }

  const buttonSecond = {
    text: {
      pl: 'Dokumenty',
      en: 'Documents',
      ua: 'Документи',
    },
    link: '/klient/dokumenty/',
  }

  return (
    <Layout>
      <Seo
        title={seo.title[lang]}
        desc={seo.desc[lang]}
        url={seo.url}
        keywords={seo.keywords}
        noIndex
      />
      <Intro
        data={{ ...intro, button, buttonSecond }}
        image={image.childImageSharp}
        position='bottom'
      />
      <Main h={1} title={main.title[lang]} article={main.html[lang]}>
        <Events data={files} />
        <article css={[sHtml, sHtmlWrapper]}>
          <p>Aby dowiedzieć się, w jaki sposób Omida Sea and Air przetwarza Państwa dane osobowe, zachęcamy do zapoznania się z poniższymi klauzulami informacyjnymi, które dotyczą:</p>
        </article>
        <Events data={files2} />
      </Main>
    </Layout>
  )
}

const sHtmlWrapper = {
  paddingTop: '4rem',
  marginTop: '2rem'
}

export default OchronaDanych
