export const seo = {
  url: 'firma/ochrona-danych',
  title: {
    pl: 'Ochrona Danych',
    en: 'Data Protection',
    ua: 'Захист даних',
  },
  desc: {
    pl: `Dowiedz się, w jaki sposób przetwarzamy dane osobowe. `,
    en: `Find out how we process personal data.`,
    ua: `Дізнайтеся, як ми обробляємо персональні дані.`,
  },
  keywords: ['omida', 'sea and air', 'sea & air', 'ochrona danych'],
}

export const intro = {
  title: {
    pl: 'Ochrona Danych',
    en: 'Data Protection',
    ua: 'Захист даних',
  },
  desc: {
    pl: `Dowiedz się, w jaki sposób przetwarzamy dane osobowe. `,
    en: `Find out how we process personal data.`,
    ua: `Дізнайтеся, як ми обробляємо персональні дані.`,
  },
}

export const main = {
  title: {
    pl: 'Ochrona danych i polityka prywatności w Omida Sea And Air S.A.',
    en: 'Data protection and privacy policy at Omida Sea And Air S.A.',
    ua: 'Політика захисту даних і конфіденційності Omida Sea And Air S.A.',
  },
  html: {
    pl: `
    <p>
    <strong>
    Szanowni Państwo, Drodzy Klienci<br/>
    Omida Sea And Air Spółka Akcyjna (dalej: Sea And Air S.A.) szanuje prawo do ochrony danych osobowych oraz prawo do prywatności swoich Klientów, pracowników, kontrahentów i partnerów biznesowych.
    </strong>
    </p>
    <p>
    W związku z powyższym Sea And Air S.A. wyznaczyło inspektora ochrony danych. Jest to osoba, z którą należy kontaktować we wszystkich sprawach dotyczących przetwarzania danych osobowych oraz korzystania z praw związanych z przetwarzaniem danych. Z inspektorem można się kontaktować w następujący sposób:
    </p>
    <ul>
      <li>listownie na adres: ul. Plac Kaszubski 8, 81-350 Gdynia;</li>
      <li>przez e-mail: <a href="mailto:iodo@seaandair.pl">iodo@seaandair.pl</a>
    </ul>
    <p>
    Polityka ochrony danych osobowych spółki Omida Sea And Air S.A. w zakresie międzynarodowych i krajowych usług spedycyjnych, logistycznych, przewozowych, agencji celnych oraz innych związanych z obsługą obrotu towarowego.
    </p>
    `,
    en: `
    <p>
    <strong>
    Data Protection and Privacy Policy<br/>
    Omida Sea And Air Spółka Akcyjna (hereinafter: Sea And Air) respects the right to the protection of personal data and the right to privacy of its clients, employees, contractors and business partners.
    </strong>
    </p>
    <p>
    Therefore, Omida Sea And Air S.A. has appointed a data protection officer. It is a person to be contacted in all matters relating to the processing of personal data and the exercise of rights related to data processing. The inspector can be contacted in the following way:
    </p>
    <ul>
      <li>by letter to the following address: ul. Plac Kaszubski 8, 81-350 Gdynia;</li>
      <li>by email: <a href="mailto:iodo@seaandair.pl">iodo@seaandair.pl</a>
    </ul>
    <p>
    Data Protection Policy of Omida Sea And Air S.A. within the scope of the international and domestic forwarding, logistics, transport, customs agencies and other services related to the handling of trade in goods - document to download.
    </p>
    `,
    ua: `
    <p>
    <strong>
    Пані та панове, шановні клієнти<br/>
    Omida Sea And Air Spółka Akcyjna (далі: Sea And Air S.A.) поважає право на захист персональних даних і право на конфіденційність своїх клієнтів, співробітників, підрядників і ділових партнерів.</strong>
    </p>
    <p>
    Тому Sea And Air S.A. призначила уповноваженого із захисту даних. Це особа, з якою можна зв’язатися з усіх питань, пов’язаних з обробкою персональних даних і здійсненням прав, пов’язаних з обробкою даних. Зв'язатися з інспектором можна в такий спосіб:
    </p>
    <ul>
      <li>листом на адресу: вул. Plac Kaszubski 8, 81-350 Gdynia;</li>
      <li>електронною поштою: <a href="mailto:iodo@seaandair.pl">iodo@seaandair.pl</a>
    </ul>
    <p>
    Політика захисту персональних даних Omida Sea And Air S.A. у сфері міжнародного та внутрішнього експедирування, логістики, транспорту, митних агентств та інших послуг, пов’язаних з обслуговуванням торгівлі товарами.
    </p>
    `,
  },
}
